import React, { useEffect } from "react";
import { useNavigate } from "react-router";
import NotificationForm from "containers/NotificationForm";
import { useDispatch, useSelector } from "react-redux";
import { addNotification, selectNotifLoading } from "store/slices/notification";
import { useTranslation } from "react-i18next";

import styles from "./index.module.scss";

const AddNotification = () => {
  const { t } = useTranslation(["common"]);
  const isLoading = useSelector(selectNotifLoading);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const onSubmit = async (data) => {
    try {
      await dispatch(addNotification(data));
      navigate(-1);
    } catch (e) {
      navigate(-1);
    }
  };

  const cancel = () => {
    navigate(-1);
  };

  useEffect(() => {
    document.title = t("common:notificationPage.newEvent");
  }, []);

  return (
    <div className={styles.notification}>
      <span className={styles["notification__title"]}>
        {t("common:notificationPage.addEvent")}
      </span>
      <div className={styles["notification__body"]}>
        <NotificationForm cancelHandler={cancel} submitHandler={onSubmit} />
      </div>
    </div>
  );
};

export default AddNotification;
