export const SIDE_MENU = [
  {
    label: "Події",
    translateKey: "events",
    id: 0,
    link: "/events",
    childrenLinks: [
      {
        label: "Додати подію",
        translateKey: "addEvent",
        link: "/events/new-event",
        id: 11,
      },
      {
        label: "Не опубліковані події",
        translateKey: "unpublishedEvents",
        link: "/events/unpublished-event",
        id: 12,
      },
      {
        label: "Календар подій",
        translateKey: "calendarEvents",
        link: "/events/published",
        id: 13,
      },
      {
        label: "Активні події",
        translateKey: "activeEvents",
        link: "/events/active",
        id: 14,
      },
      {
        label: "Події, що потребують експірації",
        translateKey: "expirateEvents",
        link: "/events/expirate-event",
        id: 15,
      },
      {
        label: "Події, що потребують розрахунку",
        translateKey: "calculateEvents",
        link: "/events/calculate-event",
        id: 16,
      },
      {
        label: "Завершені події",
        translateKey: "finishedEvents",
        link: "/events/finished-events",
        id: 17,
      },
      {
        label: "Видалені події",
        translateKey: "deletedEvents",
        link: "/events/deleted-events",
        id: 18,
      },
    ],
  },
  {
    label: "Статистика",
    translateKey: "stat",
    link: "/statistic",
    id: 1,
  },
  {
    label: "Користувачі",
    translateKey: "users",
    link: "/users",
    id: 1,
  },
  {
    label: "Зображення",
    translateKey: "images",
    link: "/images",
    id: 1,
  },
  {
    label: "Адміністратори",
    translateKey: "admins",
    link: "/admins",
    id: 1,
  },
  {
    label: "Повідомлення",
    translateKey: "notifications",
    link: "/notification",
    id: 1,
  },
  {
    label: "Редагування балансу",
    translateKey: "editBalance",
    link: "/balance/edit",
    id: 1,
  },
];

export const accessMatrixGenerator = (role) => {
  switch (role) {
    case "ROLE_SUB_ADMIN":
      return [
        {
          label: "Події",
          translateKey: "events",
          id: 0,
          link: "/events",
          childrenLinks: [
            {
              label: "Додати подію",
              translateKey: "addEvent",
              link: "/events/new-event",
              id: 11,
            },
            {
              label: "Не опубліковані події",
              translateKey: "unpublishedEvents",
              link: "/events/unpublished-event",
              id: 12,
            },
            {
              label: "Календар подій",
              translateKey: "calendarEvents",
              link: "/events/published",
              id: 13,
            },
            {
              label: "Активні події",
              translateKey: "activeEvents",
              link: "/events/active",
              id: 14,
            },
            {
              label: "Події, що потребують експірації",
              translateKey: "expirateEvents",
              link: "/events/expirate-event",
              id: 15,
            },
            {
              label: "Події, що потребують розрахунку",
              translateKey: "calculateEvents",
              link: "/events/calculate-event",
              id: 16,
            },
            {
              label: "Завершені події",
              translateKey: "finishedEvents",
              link: "/events/finished-events",
              id: 17,
            },
            {
              label: "Видалені події",
              translateKey: "deletedEvents",
              link: "/events/deleted-events",
              id: 18,
            },
          ],
        },
        {
          label: "Статистика",
          translateKey: "stat",
          link: "/statistic",
          id: 1,
        },
        {
          label: "Користувачі",
          translateKey: "users",
          link: "/users",
          id: 1,
        },
        {
          label: "Зображення",
          translateKey: "images",
          link: "/images",
          id: 1,
        },
      ];

    case "ROLE_TECH_SUPPORT":
      return [
        {
          label: "Користувачі",
          translateKey: "users",
          link: "/users",
          id: 1,
        },
      ];

    case "ROLE_MARKETER":
      return [
        {
          label: "Статистика",
          translateKey: "stat",
          link: "/statistic",
          id: 1,
        },
        {
          label: "Користувачі",
          translateKey: "users",
          link: "/users",
          id: 1,
        },
      ];

    default:
      return SIDE_MENU;
  }
};
