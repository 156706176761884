import moment from "moment";
import "moment-timezone";

const parseDateRange = (dateRange) => {
  const { startDate, endDate } = dateRange;
  const parseStartDate = moment(startDate).format("DD MMM YYYY");
  const parseEndDate = moment(endDate).format("DD MMM YYYY");
  return parseStartDate + " - " + parseEndDate;
};
const parseDateToFormat = (date, format = "") => {
  return moment(date).format(format);
};
const parseUnixToFormat = (date, format = "DD MMM YYYY") => {
  return moment.unix(date).format(format);
};
const getDateWithTZ = (date) => {
  let d = new Date(date);
  let curr_date = moment(d).format("DD.MM.YYYY HH:mm UTCZ");
  return curr_date.substring(curr_date.length - 3, 0);
};
const formatTimezoneForCalendar = (date, timezone) => {
  if (timezone === undefined) {
    return date;
  }
  const dateWithoutZone = moment(date).format("YYYY-MM-DDTHH:mm:ss.SSS");
  const otherZone = moment.tz(date, timezone).format("Z");
  const dateWithOtherZone = [dateWithoutZone, otherZone].join("");

  return new Date(dateWithOtherZone);
};
const getTimeForTimer = (date2) => {
  let d1 = new Date();
  let d2 = new Date(date2);
  let temp_time = (d2.getTime() - d1.getTime()) / 1000;
  const time = new Date();
  time.setSeconds(time.getSeconds() + temp_time);
  return time;
};
const countPercent = (date, startDate) => {
  let date1 = new Date(date);
  let date2 = new Date();
  let start = new Date(startDate);
  let time1 = date1.getTime();
  let time2 = date2.getTime();
  let start_time = start.getTime();
  let t1 = time1 - start_time;
  let t2 = time2 - start_time;
  let percent = (t2 * 100) / t1;
  // console.log("percent", percent);
  return percent;
};

const formatDateToCustomFormat = (date) => {
  if (date) {
    const options = { year: "numeric", month: "short", day: "2-digit" };
    const formattedDate = date.toLocaleDateString("en-US", options);
    return formattedDate.replace(",", "");
  }
};

const formatDateTimeForHeader = (date) => {
  const day = String(date.getDate()).padStart(2, "0");
  const month = String(date.getMonth() + 1).padStart(2, "0");
  const year = date.getFullYear();

  const hours = String(date.getHours()).padStart(2, "0");
  const minutes = String(date.getMinutes()).padStart(2, "0");

  const formattedDateTime = `${day}-${month}-${year} ${hours}:${minutes}`;

  return formattedDateTime;
};

const formatDateForApiCall = (date) => {
  if (date) {
    const options = { day: "2-digit", month: "2-digit", year: "numeric" };
    const formattedDate = date.toLocaleDateString("en-US", options);
    const [month, day, year] = formattedDate.split("/");
    return `${day}-${month}-${year}`;
  }
};

const setDateToOneMonthEarlier = () => {
  const initialDate = new Date();
  initialDate.setMonth(initialDate.getMonth() - 1);
  return initialDate;
};

export {
  parseDateRange,
  parseDateToFormat,
  parseUnixToFormat,
  getDateWithTZ,
  formatTimezoneForCalendar,
  getTimeForTimer,
  countPercent,
  formatDateToCustomFormat,
  formatDateForApiCall,
  formatDateTimeForHeader,
  setDateToOneMonthEarlier,
};
// export const formatUTC = (dateInt, addOffset) => {
//   let date = new Date(dateInt);
//   const offsetDate = new Date();
//   if (addOffset === undefined) {
//     return date;
//   }
//   offsetDate.setTime(date.getTime() + -addOffset * 60 * 60000);
//   return offsetDate;
// };
