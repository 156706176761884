/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import cx from "classnames";

import styles from "./index.module.scss";

const checkId = (filterQuery, data) => {
  if (!filterQuery && data.value === "ALL") {
    return true;
  }

  if (!filterQuery) {
    return false;
  }

  const arr = filterQuery.split(",");
  return arr.find((item) => item === data.value);
};

const FilterItem = ({ data, onSelect = () => {} }) => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const filterQuery = searchParams.get("status");
  const [isActive, setIsActive] = useState(checkId(filterQuery, data));

  const selectFilter = () => {
    const params = new URLSearchParams(searchParams);

    if (data.id === -1) {
      params.delete("status");
      params.set("page", "1")
    } else {
      params.set("status", data.value);
      params.set("page", "1")
    }

    navigate(
      {
        search: params.toString(),
      },
      {
        replace: true,
      }
    );

    onSelect(data.id);
  };

  useEffect(() => {
    setIsActive(checkId(filterQuery, data));
  }, [filterQuery]);

  return (
    <div
      className={cx(styles["filter-item"], {
        [styles.active]: Boolean(isActive),
      })}
      onClick={selectFilter}
    >
      <div
        className={styles["filter-item__inner"]}
        style={{ "--filter-color": data.color }}
      >
        <span>{data.title}</span>
      </div>
    </div>
  );
};

export default FilterItem;
