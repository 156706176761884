import React, { useEffect, useRef } from "react";
import { useController } from "react-hook-form";
import cx from "classnames";

import styles from "./index.module.scss";

const InputControl = (props) => {
  const { size = "md", isPlaceholderWhite } = props;
  const { field } = useController(props);

  const ref = useRef(null);

  const resize = () => {
    function OnInput() {
      this.style.height = 0;
      this.style.height = this.scrollHeight + 4 + "px";
    }
    ref.current.setAttribute("style", "height:50px;overflow-y:hidden;");
    // styles for default inited input
    ref.current.style.height = ref.current.scrollHeight + 4 + "px";
    ref.current.addEventListener("input", OnInput, false);
  };

  useEffect(() => {
    if (ref.current) {
      resize();
    }
  }, [field.value]);

  return (
    <>
      <div
        className={cx(styles["text-area"], {
          [styles[`${size}`]]: size,
          [styles["placeholder-white"]]: isPlaceholderWhite,
          [styles["is-empty"]]: !field.value,
        })}
      >
        <textarea
          {...props}
          {...field}
          ref={ref}
          onBlur={() => {
            field.onBlur();
            if (props.onBlur) {
              props.onBlur();
            }
          }}
        />
      </div>
    </>
  );
};

const InputWithoutControl = (props) => {
  const { size = "md", isPlaceholderWhite } = props;

  const ref = useRef(null);

  const resize = () => {
    function OnInput() {
      this.style.height = 0;
      this.style.height = this.scrollHeight + 4 + "px";
    }
    ref.current.setAttribute("style", "height:50px;overflow-y:hidden;");
    // styles for default inited input
    ref.current.style.height = ref.current.scrollHeight + 4 + "px";
    ref.current.addEventListener("input", OnInput, false);
  };

  useEffect(() => {
    if (ref.current) {
      resize();
    }
  }, [ref]);

  return (
    <>
      <div
        className={cx(styles["text-area"], {
          [styles[`${size}`]]: size,
          [styles["placeholder-white"]]: isPlaceholderWhite,
        })}
      >
        <textarea {...props} ref={ref} />
      </div>
    </>
  );
};

const AutoResizeTextArea = (props) => {
  if (props.control) {
    return <InputControl {...props} />;
  }

  return <InputWithoutControl {...props} />;
};

export default AutoResizeTextArea;
