import React, { useState } from "react";
import { Table } from "react-bootstrap";
import cx from "classnames";
import NotificationCol from "./NotificationCol";
import UserCol from "./UserCol";
import AdditionalCol from "./AdditionalCol";
import HistoryCol from "./HistoryCol";
import SortButton from "./SortButton";
import BalanceCol from "./BalanceCol";
import AdminCol from "./AdminCol";
import HoursCol from "./HoursCol";
import ActionsCol from "./ActionsCol";

import styles from "./index.module.scss";

export const TABLE_VIEW = {
  NOTIFICATION: "notification",
  BALANCE: "balance",
  USERS: "users",
  HISTORY: "history",
  ADMIN: "admin",
  HOURS_WORKED: "hours",
  USER_ACTIONS: "actions",
};

const TableComponent = ({
  data,
  type = "notification",
  loggedUserRole,
  userHistoryOpenedModal,
  setBlockModal,
  setUnblockModal,
  blockHandler = () => {},
  userHoursHandler = () => {},
  pauseHandler = () => {},
  deleteHandler = () => {},
  editHandler = () => {},
  openModal = () => {},
  changeStatus = () => {},
  setUserId,
  setAdminName,
  setUserEmail,
  setUserRole,
  keyword,
  sortByObj,
  setDeleteModal,
  setResetPassModal,
  userActionsHandler,
  userBidsActivityHandler,
  setUsername,
}) => {
  const [expandRow, setExpandRow] = useState(-1);
  return (
    <div
      className={cx(styles["table-box"], {
        [styles[type]]: !!type,
      })}
    >
      <Table size="sm" bordered hover>
        <thead>
          <tr>
            {data?.header?.map((item) => {
              return (
                <th key={item.key}>
                  <span
                    style={{
                      paddingRight: item.sort ? "20px" : "0",
                    }}
                  >
                    {item.label}{" "}
                    {item.sort && item?.sortOptions && (
                      <SortButton
                        sortKey={item.key}
                        sortOptions={item?.sortOptions}
                      />
                    )}
                  </span>
                </th>
              );
            })}
          </tr>
        </thead>
        <tbody>
          {data?.data?.map((item, index) => {
            return (
              <>
                {type === TABLE_VIEW.NOTIFICATION && (
                  <tr key={item?.id}>
                    <NotificationCol
                      data={item}
                      headers={data.header}
                      deleteHandler={deleteHandler}
                      editHandler={editHandler}
                      openModal={openModal}
                    />
                  </tr>
                )}

                {type === TABLE_VIEW.HISTORY && (
                  <tr key={index}>
                    <HistoryCol
                      data={item}
                      headers={data.header}
                      deleteHandler={deleteHandler}
                      editHandler={editHandler}
                      openModal={openModal}
                      loggedUserRole={loggedUserRole}
                    />
                  </tr>
                )}

                {type === TABLE_VIEW.USERS && (
                  <>
                    <tr
                      className={cx({
                        [styles.expanded]: expandRow === index,
                        [styles.deleted]: item.status === "DELETED",
                      })}
                    >
                      <UserCol
                        data={item}
                        headers={data.header}
                        position={index}
                        userHistoryOpenedModal={userHistoryOpenedModal}
                        expandRow={expandRow}
                        setExpandRow={setExpandRow}
                        changeStatus={changeStatus}
                        deleteHandler={deleteHandler}
                        openModal={openModal}
                        loggedUserRole={loggedUserRole}
                        userBidsActivityHandler={userBidsActivityHandler}
                        setUsername={setUsername}
                      />
                    </tr>
                    <tr
                      className={cx(styles.subrow, {
                        [styles.expanded]: expandRow === index,
                      })}
                    >
                      <AdditionalCol data={item} />
                    </tr>
                  </>
                )}

                {type === TABLE_VIEW.BALANCE && (
                  <>
                    <tr>
                      <BalanceCol
                        data={item}
                        headers={data.header}
                        position={index}
                        openModal={openModal}
                        sortByObj={sortByObj}
                        keyword={keyword}
                      />
                    </tr>
                  </>
                )}

                {type === TABLE_VIEW.ADMIN && (
                  <tr>
                    <AdminCol
                      setUserId={setUserId}
                      setAdminName={setAdminName}
                      setUserEmail={setUserEmail}
                      setUserRole={setUserRole}
                      key={data.header.key}
                      data={item}
                      headers={data.header}
                      openModal={openModal}
                      setBlockModal={setBlockModal}
                      setUnblockModal={setUnblockModal}
                      setDeleteModal={setDeleteModal}
                      setResetPassModal={setResetPassModal}
                      editHandler={editHandler}
                      userHoursHandler={userHoursHandler}
                      userActionsHandler={userActionsHandler}
                    />
                  </tr>
                )}

                {type === TABLE_VIEW.HOURS_WORKED && (
                  <>
                    <tr>
                      <HoursCol
                        key={data.header.key}
                        data={item}
                        headers={data.header}
                      />
                    </tr>
                  </>
                )}

                {type === TABLE_VIEW.USER_ACTIONS && (
                  <>
                    <tr>
                      <ActionsCol
                        key={data.header.key}
                        data={item}
                        headers={data.header}
                      />
                    </tr>
                  </>
                )}
              </>
            );
          })}
        </tbody>
      </Table>
    </div>
  );
};

export default TableComponent;
