import React, { useEffect, useRef, useState } from "react";
import ReactDOMServer from "react-dom/server";
import ReactTooltip from "react-tooltip";
import { OverlayTrigger, Popover } from "react-bootstrap";
import { useWindowWidth } from "@react-hook/window-size";

import styles from "./style.module.scss";

const TooltipComponent = ({
  textTooltip,
  trigger,
  left,
  count,
  line,
  placement = "top",
  showTooltip = false,
  maxWidth = "300px",
}) => {
  const target = useRef(null);
  const [show, setShow] = useState(showTooltip);
  const [randomID, setRandomID] = useState(String(Math.random()));
  const ref = useRef();
  const ref1 = useRef();
  const width = useWindowWidth();
  const isMobile = () => width < 577;

  useEffect(() => {
    setShow(showTooltip);
  }, [showTooltip]);

  useEffect(() => {
    if (line && count) {
      let height = ref.current?.offsetHeight;
      if (height > line * count) {
        setShow(true);
      } else {
        setShow(false);
      }
    }
  });

  const renderTooltip = (props) => (
    <Popover
      id="button-tooltip"
      {...props}
      style={{
        ...props.style,
        left: left,
        maxWidth: maxWidth,
        textAlign: "center",
      }}
      body
    >
      {textTooltip}
    </Popover>
  );
  return (
    <>
      <div ref={ref1} className={[styles.wrapperTooltip]}>
        {isMobile() ? (
          <>
            {show ? (
              <OverlayTrigger
                target={target.current}
                delay={{ show: 250, hide: 400 }}
                overlay={renderTooltip}
                placement={placement}
              >
                {({ placement, arrowProps, show: _show, popper, ...props }) => (
                  <span style={{ display: "inline-block" }} {...props}>
                    {trigger}
                  </span>
                )}
              </OverlayTrigger>
            ) : (
              <span style={{ display: "inline-block" }}>{trigger}</span>
            )}
          </>
        ) : (
          <>
            <p
              data-for={randomID}
              ref={ref1}
              data-html={true}
              data-tip={ReactDOMServer.renderToString(
                show && <div className={styles.bgTooltip}>{textTooltip}</div>
              )}
            >
              {trigger}
            </p>
            {
              <ReactTooltip
                id={randomID}
                place={"right"}
                arrowColor={"#3DDDE8"}
                delayHide={200}
                style={{ maxWidth: maxWidth }}
                className={
                  maxWidth == "100%"
                    ? `${styles.tooltip} ${styles.full}`
                    : styles.tooltip
                }
                offset={{
                  bottom: `${ref?.current?.offsetHeight / 2}px`,
                }}
              />
            }
          </>
        )}
        <span
          style={{ maxWidth: ref1?.current?.offsetWidth }}
          className={styles.hiddenTrigger}
          ref={ref}
        >
          {textTooltip}
        </span>
      </div>
    </>
  );
};
export default TooltipComponent;
