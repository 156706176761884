import { configureStore } from "@reduxjs/toolkit";

import auth from "./slices/auth";
import event from "./slices/event";
import eventCategory from "./slices/eventCategory";
import steps from "./slices/steps";
import image from "./slices/image";
import notification from "./slices/notification";
import langSlice from "./slices/langSlice";
import users from "./slices/users";

export default configureStore({
  reducer: {
    auth,
    event,
    eventCategory,
    steps,
    image,
    notification,
    langSlice,
    users,
  },
});
