import React, { useState, useEffect } from "react";
import styles from "./index.module.scss";
import cx from "classnames";
import ReactDOM from "react-dom";
import { Trans, useTranslation } from "react-i18next";
import Button from "../../components/Button";
import Modal from "../../components/CustomModal";
import { useOuterClick } from "../../utils/useOuterClick";
import { fixNestedModalsCloseTrigger } from "utils/fixNestedModalsCloseTrigger";
import Input from "components/Input";
import { useForm } from "react-hook-form";
import SelectInput from "components/SelectInput";
import { roles } from "constants/roles";
import ModalWarning, {
  ErrorText,
  SuccessText,
} from "../../containers/ModalWarning";

const ModalAdminUser = ({
  isEdit,
  isResetPass,
  isOpen,
  userId,
  username,
  userEmail,
  userRole,
  setIsOpenModal,
  createUserHandler = () => {},
  updateUserHandler = () => {},
  resetPassHandler = () => {},
}) => {
  const [mounted, setMounted] = useState(false);
  const { t } = useTranslation(["button", "common", "modals"]);
  const [isCancelModalOpen, setIsCancelModalOpen] = useState(false);
  const [isSaveModalOpen, setIsSaveModalOpen] = useState(false);
  const [selectPlaceHolder, setSelectPlaceHolder] = useState("");

  const cancelHandler = () => {
    setIsCancelModalOpen(!isCancelModalOpen);
  };

  const saveHandler = () => {
    setIsSaveModalOpen(!isSaveModalOpen);
  };

  const confirmationModalSuccessHandler = () => {
    if (isEdit) {
      updateUserHandler(userId, getValues().role);
    } else if (isResetPass) {
      resetPassHandler(userId, getValues());
    } else {
      createUserHandler(getValues());
    }
    setIsSaveModalOpen(!isSaveModalOpen);
    setIsOpenModal();
    reset();
  };

  const { control, handleSubmit, formState, reset, getValues, setValue } =
    useForm({
      reValidateMode: "onChange",
      mode: "onTouched",
      defaultValues: {
        username: "",
        email: "",
        role: "",
        password: "",
        confirmPassword: "",
      },
    });

  const innerRef = useOuterClick((ev) => {
    if (mounted && isOpen) {
      cancelHandler();
      setIsOpenModal();
    }
  });

  useEffect(() => {
    if (isEdit) {
      if (userRole && userRole.length) {
        const role = roles.find((role) => role.value === userRole[1].name);

        setSelectPlaceHolder(role.label);
      }

      setValue("username", username ? username : "");
      setValue("email", userEmail ? userEmail : "");
    }
  }, [isOpen]);

  useEffect(() => {
    if (isOpen) {
      setTimeout(() => {
        setMounted(true);
      }, 250);
    }

    return () => {
      setMounted(false);
    };
  }, [isOpen]);

  return ReactDOM.createPortal(
    <>
      <Modal setIsOpenModal={setIsOpenModal} isOpen={isOpen}>
        <div
          ref={innerRef}
          className={cx(styles["modal-addUser"])}
          onClick={fixNestedModalsCloseTrigger}
        >
          <div className={styles["modal-addUser__title-wrapper"]}>
            <span className={styles["modal-addUser__title"]}>
              {isEdit ? "Редагувати співробітника" : "  Додати співробітника"}
            </span>
          </div>

          <div className={styles["modal-addUser__form"]}>
            {isResetPass ? null : (
              <Input
                disabled={isEdit}
                control={control}
                label="Логін"
                name="username"
                placeholder="username"
                inForm
              />
            )}

            {isResetPass ? null : (
              <Input
                disabled={isEdit}
                control={control}
                label="Email"
                name="email"
                placeholder="test@mail.com"
                inForm
              />
            )}

            {isResetPass ? null : (
              <SelectInput
                options={roles}
                control={control}
                label="Посада"
                name="role"
                inForm
                placeholder={
                  selectPlaceHolder ? selectPlaceHolder : "Select Role"
                }
              />
            )}

            {isEdit ? null : (
              <Input
                control={control}
                label="Пароль"
                name="password"
                placeholder="Password should be at least 8 characters"
                inForm
              />
            )}

            {isResetPass && (
              <Input
                control={control}
                label="Повторно введіть пароль"
                name="confirmPassword"
                inForm
              />
            )}
          </div>

          <div className={styles["modal-addUser__btns"]}>
            <Button
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                cancelHandler();
              }}
            >
              {t("cancel")}
            </Button>

            <Button
              autoFocus
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                saveHandler();
              }}
            >
              {t("save")}
            </Button>
          </div>
        </div>
      </Modal>

      <ModalWarning
        isOpen={isCancelModalOpen}
        setIsOpenModal={() => setIsCancelModalOpen(!isCancelModalOpen)}
        successHandler={() => {
          setIsCancelModalOpen(!isCancelModalOpen);
          setIsOpenModal(!isOpen);
        }}
        cancelHandler={() => setIsCancelModalOpen(!isCancelModalOpen)}
        isQuestion
        title={
          <Trans i18nKey="modals:cancelAllChanges">
            Ти впевнений, що хочеш <ErrorText>скасувати всі дії?</ErrorText>
          </Trans>
        }
      />

      <ModalWarning
        isOpen={isSaveModalOpen}
        setIsOpenModal={() => setIsCancelModalOpen(!isSaveModalOpen)}
        successHandler={() => {
          confirmationModalSuccessHandler();
        }}
        cancelHandler={() => setIsSaveModalOpen(!isSaveModalOpen)}
        isQuestion
        title={
          <Trans i18nKey="modals:allEnteredDataCorrect">
            Ти точно впевнений, що всі{" "}
            <ErrorText>дані правильно введені?</ErrorText>
          </Trans>
        }
      />
    </>,

    document.getElementById("root")
  );
};

export default ModalAdminUser;
