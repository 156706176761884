import React, { useEffect } from "react";

import "./index.scss";

const Home = () => {
  const token = localStorage.getItem("token");

  useEffect(() => {
    document.title = "MyCoin Admin Panel";
  }, []);

  useEffect(() => {
    const lang = localStorage.getItem("lang");
    localStorage.setItem("lang", !lang ? "uk" : lang.toLowerCase());
  }, [token]);

  return (
    <div className="home">
      <img src={require("../../assets/Gerb.png")} />
    </div>
  );
};

export default Home;
