/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";

import ModalWarning, { ErrorText } from "containers/ModalWarning";
import AutoResizeTextArea from "components/AutoResizeTextArea";
import AutoTranslateButton from "components/AutoTranslateButton";
import CopyToBufferButton from "components/CopyToBufferButton";
import DatePickerDropdown from "components/DatePickerDropdown";
import SelectInput from "components/SelectInput";
import Button from "components/Button";
import { LANGS } from "constants/lang";
import { Trans, useTranslation } from "react-i18next";

import styles from "./index.module.scss";
import { notificationTypes } from "../../constants/notificationTypes";
import { useSelector } from "react-redux";
import { selectNotifLoading } from "../../store/slices/notification";
import Loader from "../../components/Loader";

const NotificationForm = ({
  isEditForm,
  notification,
  submitHandler = () => {},
  cancelHandler = () => {},
}) => {
  const { t } = useTranslation(["button", "modals", "common", "notification"]);
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [isCancelModal, setIsCancelModal] = useState(false);
  const isLoading = useSelector(selectNotifLoading);
  const dateNow = new Date();
  const { control, handleSubmit, formState, getValues, setValue, trigger, register } = useForm({
    reValidateMode: "onChange",
    mode: "onTouched",
    defaultValues: {
      uk: "",
      en: "",
      de: "",
      fr: "",
      es: "",
      kk: "",
      pl: "",
      status: isEditForm ? notificationTypes.find(el => el.value === notification?.statusName) : notificationTypes[0],
      endDate: isEditForm ? new Date(notification?.endDate) : dateNow
    }
  });
  const { errors, isValid } = formState;
  const onSubmitHandler = () => {
    setIsCancelModal(false);
    setIsOpenModal(true);
  };

  const cancel = (e) => {
    e.preventDefault();
    setIsCancelModal(true);
    setIsOpenModal(true);
  };

  const getSubmitModalText = () => {
    if (isCancelModal) {
      return (
        <span>
          <Trans i18nKey="modals:cancelAllChanges">
            Ти впевнений, що хочеш <ErrorText>скасувати всі дії?</ErrorText>
          </Trans>
        </span>
      );
    }
    if (isValid) {
      return (
        <span>
          <Trans i18nKey="modals:allEnteredDataCorrect">
            Ти точно впевнений, що всі{" "}
            <ErrorText isUpperCase>дані правильно введені?</ErrorText>
          </Trans>
        </span>
      );
    }
    return (
      <span>
        <Trans i18nKey="modals:cannotSaveNoFullData">
          Усі поля не заповнені, тому ти{" "}
          <ErrorText>не можеш зберегти!</ErrorText>
        </Trans>
      </span>
    );
  };
  useEffect(() => {
    if (isEditForm && notification) {
      setValue("status", notificationTypes.find(el => el.value === notification?.status));
      setValue("endDate", new Date(notification?.endDate));
      setValue("uk", notification?.messages?.uk);
      setValue("en", notification?.messages?.en);
      setValue("de", notification?.messages?.de);
      setValue("fr", notification?.messages?.fr);
      setValue("es", notification?.messages?.es);
      setValue("kk", notification?.messages?.kk);
      setValue("pl", notification?.messages?.pl);
      trigger()
    }

  }, [notification, isEditForm]);

  return (
    <>
      {!isLoading ? <div className={styles["form-container"]}>
        <form className={styles["form"]} onSubmit={handleSubmit(onSubmitHandler)}>
          <div className={styles["form__header"]}>
            <div className={styles["form__header-inner"]}>
              <Button
                width={30}
                height={30}
                type="submit"
                onClick={() => {
                  if (!isValid) {
                    setIsCancelModal(false);
                    setIsOpenModal(true);
                  }
                }}
              >
                {t("button:save")}
              </Button>
              <Button width={30} height={30} type="cancel" onClick={cancel}>
                {t("button:cancel")}
              </Button>
              <div onClick={(e) => e.stopPropagation()}>
                <DatePickerDropdown
                  label={
                    isEditForm
                      ? t("notification:expirationDate")
                      : t("notification:expirationDate_2")
                  }
                  control={control}
                  name="endDate"
                  initValue={isEditForm ? new Date(notification?.endDate) : dateNow}
                  rules={{
                    required: t("common:form.validation.required")
                  }}
                  errors={errors.endDate}
                  onChange={(e) => console.log(e)}
                  minDate={new Date()}
                />
              </div>

              <SelectInput
                control={control}
                name="status"
                label={
                  isEditForm
                    ? t("notification:statusNotification")
                    : t("notification:statusNotification_2")
                }
                options={notificationTypes}
                rules={{
                  required: t("common:form.validation.required")
                }}
                errors={errors.status}
              />
            </div>
          </div>
          <div className={styles["form__body"]}>
            {Object.keys(LANGS).map((item, index) => (
              <div className={styles["form__body-row"]} key={item}>
                <div>
                  {index === 0 && (
                    <div className={styles["form__body-title"]}>
                      <span>{t("notification:enterNotification")}</span>
                    </div>
                  )}
                  <AutoResizeTextArea
                    control={control}
                    name={item}
                    placeholder={LANGS[item]}
                    isPlaceholderWhite
                    rules={{
                      required: t("common:form.validation.required")
                    }}
                    errors={errors[item]}
                  />
                </div>
                <CopyToBufferButton
                  getText={() => getValues(item)}
                  onCopy={(txt) => {
                    setValue("uk", txt);
                    setValue("en", txt);
                    setValue("de", txt);
                    setValue("fr", txt);
                    setValue("es", txt);
                    setValue("kk", txt);
                    setValue("pl", txt);
                    trigger();
                  }}
                />
                <AutoTranslateButton
                  getText={() => ({
                    currentText: getValues(item),
                    allTextValues: getValues(),
                    langs: LANGS
                  })}
                  onTranslate={(data) => {
                    setValue("uk", data.uk);
                    setValue("en", data.en);
                    setValue("de", data.de);
                    setValue("fr", data.fr);
                    setValue("es", data.es);
                    setValue("kk", data.kk);
                    setValue("pl", data.pl);
                    trigger();
                  }}
                />
              </div>
            ))}
          </div>
        </form>
      </div> : <Loader />}
      <ModalWarning
        isOpen={isOpenModal}
        hideCancelBtn={!isValid && !isCancelModal}
        isQuestion={isValid || isCancelModal}
        setIsOpenModal={() => setIsOpenModal(!isOpenModal)}
        cancelHandler={() => {
          setIsCancelModal(false);
        }}
        successHandler={() => {
          const values = getValues();
          if (!isCancelModal && isValid) {
            submitHandler({
              status: values?.status.value,
              endDate: values?.endDate,
              messages: {
                uk: values.uk,
                en: values.en,
                de: values.de,
                fr: values.fr,
                es: values.es,
                kk: values.kk,
                pl: values.pl
              }
            });
          }
          if (!isCancelModal && !isValid) {
            setIsOpenModal(false);
          }
          if (isCancelModal) {
            cancelHandler();
          }
        }}
        title={getSubmitModalText()}
      />
    </>
  );
};

export default NotificationForm;
