/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { selectSelectedEventCategoryId } from "../../../store/slices/eventCategory";
import { clearEventState } from "store/slices/event";
import Loader from "../../../components/Loader";
import Tips, { TIPS_TYPE } from "../../../containers/Tips";
import EventList from "containers/EventList";
import { useTranslation } from "react-i18next";
import {
  getFilteredEvent,
  selectEventList,
  selectIsEventLoading,
} from "store/slices/event";
import dogTip1 from "../../../assets/events/dog-1.png";

import styles from "./index.module.scss";

const ActiveEvent = () => {
  const { t } = useTranslation(["event"]);
  const dispatch = useDispatch();
  const [tipType, setTipType] = useState();
  const [selectedPage, setSelectedPage] = useState(0);
  const isEventCategoryLoading = useSelector(
    (state) => state.eventCategory.isEventCategoryLoading
  );
  const selectedCategory = useSelector(selectSelectedEventCategoryId);
  const ActiveEventList = useSelector(selectEventList);
  const isEventListLoading = useSelector(selectIsEventLoading);

  const pageRef = useRef(selectedPage);

  const setTip = () => {
    if (selectedCategory) {
      setTipType(TIPS_TYPE.CLICK);
    } else {
      setTipType(TIPS_TYPE.CHOOSE_CATEGORY);
    }
  };

  const getList = (page) => {
    dispatch(
      getFilteredEvent({
        params: {
          include: "ACTIVE,PASSIVE",
          category: selectedCategory,
          page,
        },
      })
    );
  };

  useEffect(() => {
    if (selectedCategory) {
      getList(selectedPage);
      return;
    }
    setTip();
  }, [selectedCategory]);

  useEffect(() => {
    document.title = t("activeEvent");

    let timerId = setInterval(() => {
      if (selectedCategory) {
        getList(pageRef.current);
      }
    }, 10000);

    return () => {
      clearInterval(timerId);

      dispatch(clearEventState());
    };
  }, [selectedCategory]);

  return (
    <div className={styles["active-event"]}>
      <div className={styles.content}>
        {!selectedCategory && (
          <div className={styles["content__tips"]}>
            <Tips type={tipType} title={t("activeEvent")} />
          </div>
        )}

        {selectedCategory && (
          <div className={styles["content__page"]}>
            <EventList
              type="active"
              getEvents={getList}
              eventList={ActiveEventList}
              isEventListLoading={isEventListLoading}
              emptyListText={t("noActiveEvent")}
              listReload={() => {
                getList();
              }}
              pageChangeHandler={(page) => {
                getList(page - 1);
              }}
            />
          </div>
        )}
      </div>

      {!selectedCategory && (
        <div className="add-event__img">
          <img src={dogTip1} alt="choose-icon-2" />
        </div>
      )}

      {(isEventCategoryLoading || (selectedCategory && isEventListLoading)) && (
        <Loader />
      )}
    </div>
  );
};

export default ActiveEvent;
