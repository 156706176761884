import React from "react";
import cx from "classnames";
import CopyToBufferButton from "components/CopyToBufferButton";

import VectorUp from "assets/svg/VectorUp";
import VectorDown from "assets/svg/VectorDown";

import styles from "../index.module.scss";
import ArrowBack from "assets/svg/ArrowBack";
import Button from "components/Button";
import { useTranslation } from "react-i18next";

// MOVED TO LOCALIZATION DICTIONARY
const STATUS = {
  win: "Виграш",
  fail: "Ставка не зіграла",
  cancel: "Відмінено",
  pending: "Очікується",
  done: "Готово",
};

const HistoryCol = ({
  data,
  headers,
  deleteHandler = () => {},
  editHandler = () => {},
  openModal = () => {},
  loggedUserRole,
}) => {
  const { t } = useTranslation(["event"]);

  return headers.map((header, index) => (
    <td
      key={header.key}
      className={cx({
        [styles[`${data[header.key]}`]]: header.key === "bidStatus",
        [styles.copy]: header.key === "support_code",
      })}
    >
      {header.key === "actions" ? (
        <Button
          disabled={
            loggedUserRole === "ROLE_TECH_SUPPORT" ||
            loggedUserRole === "ROLE_MARKETER"
          }
          type="clear"
          width={18}
          height={12}
          onClick={() => openModal(header.key, data.eventUuid)}
        >
          <ArrowBack />
        </Button>
      ) : header.key === "eventBidDirection" ? (
        <div>
          {data[header.key] === "BID_UP" && <VectorUp />}
          {data[header.key] === "BID_DOWN" && <VectorDown />}
        </div>
      ) : !data[header.key] ? (
        <span>-</span>
      ) : header.key === "bidStatus" ? (
        <span
          className={cx({
            [styles["green"]]: data.bidStatus === "WIN",
            [styles["red"]]:
              data.bidStatus === "CANCELED" || data.bidStatus === "LOSE",
          })}
        >
          {t(`event:status.${data[header.key]}`)}
        </span>
      ) : (
        <span>
          {data[header.key]}{" "}
          {header.key === "support_code" && (
            <span>
              <CopyToBufferButton sm getText={() => data[header.key]} />
            </span>
          )}
        </span>
      )}
    </td>
  ));
};

export default HistoryCol;
