import React, { useState, useEffect } from "react";
import ReactDOM from "react-dom";
import Modal from "../../components/CustomModal";
import { useOuterClick } from "../../utils/useOuterClick";
import styles from "./index.module.scss";
import Button from "components/Button";
import Close from "assets/svg/Close";
import PaginationComponent from "components/PaginationComponent";
import TableComponent, { TABLE_VIEW } from "components/TableComponent";
import {
  ACTIVITY_HISTORY_HEADERS,
  ADD_HISTORY_HEADERS,
  WITHDRAW_HISTORY_HEADERS,
} from "constants/table";
import ModalEventDetail from "containers/ModalEventDetail";
import Loader from "components/Loader";

import {
  selectUsersBidsPageSize,
  selectUsersBidsTotalPages,
} from "store/slices/users";

import { fakeApi } from "./fakeApi";
import { useSelector } from "react-redux";

const ModalUserHistory = ({
  title,
  data,
  isOpen,
  setIsOpenModal,
  type,
  cancelHandler = () => {},
  userBidsActivity,
  handleUserBidsModalChangePage,
  isLoading,
  loggedUserRole,
}) => {
  const [mounted, setMounted] = useState(false);
  const [isDetailModalOpen, setIsDetailModalOpen] = useState(false);
  const [selectedEventId, setSelectedEventId] = useState(null);
  const userBidsPageSize = useSelector(selectUsersBidsPageSize);
  const userBidsTotalPages = useSelector(selectUsersBidsTotalPages);

  const mutationUserDataToHistoryTableFormat = (items, type) => {
    const tableData = {
      header: [],
      data: [],
    };

    if (type === "activity_history") {
      tableData.header = ACTIVITY_HISTORY_HEADERS;
      tableData.data = items;
    }

    if (type === "out_history") {
      tableData.header = WITHDRAW_HISTORY_HEADERS;
      tableData.data = fakeApi.withdrawHistory;
    }

    if (type === "add_history") {
      tableData.header = ADD_HISTORY_HEADERS;
      tableData.data = fakeApi.addMoneyHistory;
    }

    return tableData;
  };

  const innerRef = useOuterClick((ev) => {
    if (mounted && isOpen && !isDetailModalOpen) {
      cancelHandler();
      setIsOpenModal();
    }
  });

  const redirect = () => {
    setIsOpenModal(false);
  };

  useEffect(() => {
    if (isOpen) {
      setTimeout(() => {
        // ADD GET INFO BY data.id LOGIC
        setMounted(true);
      }, 250);
    }

    return () => {
      setMounted(false);
    };
  }, [isOpen]);

  return ReactDOM.createPortal(
    <>
      <Modal setIsOpenModal={setIsOpenModal} isOpen={isOpen}>
        <div
          ref={innerRef}
          className={styles["modal"]}
          style={{
            pointerEvents: isDetailModalOpen ? "none" : "auto",
          }}
        >
          <div className={styles["modal__header"]}>
            <div>
              <div className={styles["modal__title"]}>{title}</div>
              <div className={styles["modal__subtitle"]}>
                User: {data?.username}
              </div>
            </div>
            <div className={styles["modal__close"]}>
              <Button
                type="clear"
                onClick={(e) => {
                  e.stopPropagation();
                  cancelHandler();
                  setIsOpenModal();
                }}
              >
                <Close />
              </Button>
            </div>
          </div>

          <div className={styles["modal__table"]}>
            <TableComponent
              type={TABLE_VIEW.HISTORY}
              data={mutationUserDataToHistoryTableFormat(
                userBidsActivity,
                type
              )}
              openModal={(key, data) => {
                setSelectedEventId(data);
                setIsDetailModalOpen(true);
              }}
              loggedUserRole={loggedUserRole}
            />
          </div>

          <div className={styles["modal__pagination"]}>
            <PaginationComponent
              isAdminTbl
              itemsPerPage={userBidsPageSize}
              totalPages={userBidsTotalPages}
              forcePage={0}
              onChanePage={handleUserBidsModalChangePage}
            />
          </div>

          <ModalEventDetail
            isOpen={isDetailModalOpen}
            setIsOpenModal={() => {
              setIsDetailModalOpen(!isDetailModalOpen);
            }}
            uuid={selectedEventId}
            cancelHandler={() => {}}
            successHandler={redirect}
          />
        </div>

        {isLoading && <Loader />}
      </Modal>
    </>,
    document.getElementById("root")
  );
};

export default ModalUserHistory;
