import React from "react";
import cx from "classnames";
import Input from "components/Input";
import { useForm, useWatch } from "react-hook-form";

import styles from "../index.module.scss";
import { useDispatch } from "react-redux";
import { decrementCustomerBalance, getCustomers, incrementCustomerBalance } from "../../../store/slices/users";

const BalanceCol = ({
  data,
  headers,
  keyword,
  sortByObj
}) => {
const dispatch = useDispatch()


  const { control, handleSubmit, watch, reset  } = useForm({
    reValidateMode: "onChange",
    mode: "onTouched",
    defaultValues: {
      add_balance: null,
      minus_balance: null,
    },
  });

  const submitForm = async (headerKey, username, add_balance, minus_balance) => {
    if (username) {
       headerKey === "add_balance" ? await dispatch(incrementCustomerBalance({
        username,
        amount: add_balance
      })) : await dispatch(decrementCustomerBalance({ username, amount: minus_balance }));
      keyword ? await dispatch(getCustomers({...sortByObj, keyword})) : dispatch(getCustomers(sortByObj))
      reset();
    }
  }

  const { add_balance, minus_balance} = useWatch({control})


  return headers.map((header, index) => (
    <td
      key={header.key}
      className={cx({
        [styles["col-disabled"]]: data.disabled,
        [styles["col-form"]]:
          header.key === "add_balance" || header.key === "minus_balance",
      })}
    >
      {header.key === "add_balance" || header.key === "minus_balance" ? (
        <form onSubmit={handleSubmit(null)}>
          <Input
            control={control}
            name={header.key}
            id={header.key}
            role="balance"
            type="number"
            onSubmit = {async () => {
             await submitForm(header.key, data.username.replace(/\s/g,''), add_balance, minus_balance)
            }}
          />
        </form>
      ) : !data[header.key] ? (
        <span>-</span>
      ) : (
        <span>{data[header.key]}</span>
      )}
    </td>
  ));
};

export default BalanceCol;
