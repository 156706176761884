import React, { useEffect, useState } from "react";
import Input from "components/Input";
import { useDispatch, useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import Loader from "components/Loader";
import PaginationComponent from "components/PaginationComponent";
import TableComponent, { TABLE_VIEW } from "components/TableComponent";
import { BALANCE_HEADERS } from "constants/table";
import { numberFormat } from "utils/numberFormat";
import { useTranslation } from "react-i18next";

import styles from "./index.module.scss";
import {
  getCustomers,
  selectCustomersData,
  selectCustomersIsLoading,
} from "../../store/slices/users";
import { useSearchParams } from "react-router-dom";
import useDebounce from "../../hooks/useDebounce/useDebounce";
const mutationUserDataToTableFormat = (items = []) => {
  const tableData = {
    header: BALANCE_HEADERS,
    data: items?.map((item) => ({
      ...item,
      balance: numberFormat(item.balance) || "",
    })),
  };

  return tableData;
};

const EditBalance = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation(["common", "modals"]);

  const isLoading = useSelector(selectCustomersIsLoading);
  const listData = useSelector(selectCustomersData);

  const [searchParams] = useSearchParams();
  const emailQuery = searchParams.get("email");
  const usernameQuery = searchParams.get("username");

  const [sortByObj, setSortByObj] = useState("");
  const [selectedPage, setSelectedPage] = useState(0);

  useEffect(() => {
    emailQuery || usernameQuery
      ? setSortByObj({
          sortField: usernameQuery ? "username" : emailQuery ? "email" : "",
          sortDirection: usernameQuery
            ? usernameQuery
            : emailQuery
            ? emailQuery
            : "",
          page: selectedPage,
        })
      : setSortByObj({ page: selectedPage });
  }, [emailQuery, usernameQuery, selectedPage]);

  const { control, watch } = useForm({
    reValidateMode: "onChange",
    mode: "onTouched",
    defaultValues: {
      search: "",
    },
  });

  const keyword = useDebounce(() => watch("search"), 700);

  useEffect(() => {
    keyword
      ? dispatch(getCustomers({ ...sortByObj, keyword }))
      : dispatch(getCustomers(sortByObj));
  }, [sortByObj, dispatch, keyword]);

  useEffect(() => {
    document.title = t("common:editBalance.title");
  }, [t]);

  return (
    <div className={styles.balance}>
      <div className={styles.header}>
        <span className={styles.title}>Редагування балансу</span>
        <div className={styles.search}>
          <Input
            control={control}
            name="search"
            type="text"
            role="search"
            size="sm-2"
          />
        </div>
      </div>
      <div className={styles.body}>
        {!isLoading && (
          <>
            <div className={styles.table}>
              <TableComponent
                type={TABLE_VIEW.BALANCE}
                sort
                data={mutationUserDataToTableFormat(
                  listData?.content || listData
                )}
                keyword={keyword}
                sortByObj={sortByObj}
              />
            </div>
            <div className={styles.pagination}>
              <PaginationComponent
                itemsPerPage={1}
                totalPages={listData?.totalPages}
                forcePage={listData?.pageable?.pageNumber}
                onChanePage={(page) => {
                  setSelectedPage(page - 1);
                  dispatch(
                    getCustomers({
                      ...sortByObj,
                      page: page - 1,
                    })
                  );
                }}
              />
            </div>
          </>
        )}
        {isLoading && <Loader />}
      </div>
    </div>
  );
};

export default EditBalance;
