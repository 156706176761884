import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
  createNotificationReq,
  deleteNotificationReq, getNotificationReq,
  getNotificationsListReq, updateNotificationReq
} from "../../api/notifications";
import { toasterCreator } from "../../utils/toasterCreator";

export const getNotifications = createAsyncThunk(
  "auth/getNotifications",
  async (params, { rejectWithValue }) => {
    try {
      const { data } = await getNotificationsListReq(params);
      return data;
    } catch (error) {
      return rejectWithValue(error.response.data.message);
    }
  }
);

export const getNotification = createAsyncThunk(
  "auth/getNotification",
  async (id, { rejectWithValue }) => {
    try {
      const { data } = await getNotificationReq(id);
      return data;
    } catch (error) {
      return rejectWithValue(error.response.data.message);
    }
  }
);

export const addNotification = createAsyncThunk(
  "auth/addNotification",
  async (data, { rejectWithValue }) => {
    try {
      await createNotificationReq(data);
      toasterCreator.notifications.notificationAdded();
    } catch (error) {
      return rejectWithValue(error.response.data.message);
    }
  }
);
export const deleteNotification = createAsyncThunk(
  "auth/deleteNotification",
  async (id, { rejectWithValue }) => {
    try {
      await deleteNotificationReq(id);
      toasterCreator.notifications.notificationDeleted();
    } catch (error) {
      return rejectWithValue(error.response.data.message);
    }
  }
);

export const updateNotification = createAsyncThunk(
  "auth/updateNotification",
  async ({ id, data }, { rejectWithValue }) => {
    try {
      await updateNotificationReq(id, data);
      toasterCreator.notifications.notificationUpdated();
    } catch (error) {
      return rejectWithValue(error.response.data.message);
    }
  }
);

const initialState = {
  isLoading: false,
  isError: null,
  listData: [],
  item: null
};

const notificationSlice = createSlice({
  name: "notification",
  initialState,
  extraReducers: (builder) => {
    builder
      .addCase(getNotifications.pending, (state) => {
        state.isLoading = true;
        state.isError = false;
      })
      .addCase(getNotifications.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.listData = action.payload;
      })
      .addCase(getNotifications.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = action.payload;
        state.listData = [];
      })
      .addCase(getNotification.pending, (state) => {
        state.isLoading = true;
        state.isError = false;
      })
      .addCase(getNotification.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isError = false;
        state.item = action.payload;
      })
      .addCase(getNotification.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = action.payload;
        state.item = null;
      })
      .addCase(addNotification.pending, (state) => {
        state.isLoading = true;
        state.isError = false;
      })
      .addCase(addNotification.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isError = false;
        state.item = action.payload;
      })
      .addCase(addNotification.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = action.payload;
      });
  }
});

const { reducer } = notificationSlice;

export const selectNotification = (state) => state.notification;
export const selectNotifLoading = (state) => state.notification.isLoading;
export const selectNotifListData = (state) => state.notification.listData;
export const selectNotifItem = (state) => state.notification.item;
export const selectNotifError = (state) => state.notification.isError;

export default reducer;
