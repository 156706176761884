import Question from "assets/svg/Question";
import Stop from "assets/svg/Stop";
import React, { useState, useEffect } from "react";
import cx from "classnames";
import ReactDOM from "react-dom";
import Button from "../../components/Button";
import Modal from "../../components/CustomModal";
import { useOuterClick } from "../../utils/useOuterClick";
import { fixNestedModalsCloseTrigger } from "utils/fixNestedModalsCloseTrigger";
import { useTranslation } from "react-i18next";

import styles from "./index.module.scss";

export const SuccessText = ({ children, isUpperCase = false }) => {
  return (
    <span
      className={styles["modal-warning__success-text"]}
      style={{ textTransform: isUpperCase ? "uppercase" : "unset" }}
    >
      {children}
    </span>
  );
};

export const ErrorText = ({ children, isUpperCase = true }) => {
  return (
    <span
      className={styles["modal-warning__error-text"]}
      style={{ textTransform: isUpperCase ? "uppercase" : "unset" }}
    >
      {children}
    </span>
  );
};

const ModalWarning = ({
  title,
  isOpen,
  setIsOpenModal,
  isQuestion,
  hideCancelBtn,
  successHandler = () => {},
  cancelHandler = () => {},
}) => {
  const [mounted, setMounted] = useState(false);
  const { t } = useTranslation(["button"]);

  const innerRef = useOuterClick((ev) => {
    if (mounted && isOpen) {
      cancelHandler();
      setIsOpenModal();
    }
  });

  useEffect(() => {
    if (isOpen) {
      setTimeout(() => {
        setMounted(true);
      }, 250);
    }

    return () => {
      setMounted(false);
    };
  }, [isOpen]);

  return ReactDOM.createPortal(
    <Modal setIsOpenModal={setIsOpenModal} isOpen={isOpen}>
      <div
        ref={innerRef}
        className={cx(styles["modal-warning"], {
          [styles["is-question"]]: isQuestion,
        })}
        onClick={fixNestedModalsCloseTrigger}
      >
        <div className={styles["modal-warning__stop"]}>
          {isQuestion && <Question />}
          {!isQuestion && <Stop />}
        </div>
        <div className={styles["modal-warning__text"]}>{title}</div>
        <div className={styles["modal-warning__btns"]}>
          <Button
            autoFocus
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              successHandler();
              setIsOpenModal();
            }}
          >
            {!hideCancelBtn ? t("yes") : t("itsClear")}
          </Button>
          {!hideCancelBtn && (
            <Button
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                cancelHandler();
                setIsOpenModal();
              }}
            >
              {t("no")}
            </Button>
          )}
        </div>
      </div>
    </Modal>,
    document.getElementById("root")
  );
};

export default ModalWarning;
