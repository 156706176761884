import React from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";
import Button from "../../components/Button";
import Input from "../../components/Input";
import { login, selectAuthLoading } from "../../store/slices/auth";
import { useTranslation } from "react-i18next";
import "./index.scss";

const Login = () => {
  const dispatch = useDispatch();
  const isLoading = useSelector(selectAuthLoading);
  const navigate = useNavigate();
  const { t } = useTranslation(["button", "common"]);

  const { control, handleSubmit, formState } = useForm({
    reValidateMode: "onChange",
    mode: "onTouched",
    defaultValues: {
      login: "",
      password: "",
    },
  });
  const { errors, isValid, isDirty } = formState;

  const onSubmit = (formData) => {
    dispatch(login(formData))
      .unwrap()
      .then((res) => {
        navigate("/");
      })
      .catch();
  };

  return (
    <div className="login">
      <form onSubmit={handleSubmit(onSubmit)}>
        <Input
          placeholder={"Login"}
          control={control}
          name="login"
          id="login"
          bordered
          rules={{
            required: t("common:form.validation.required"),
          }}
          errors={errors.login}
          style={{
            height: "40px",
          }}
        />
        <Input
          placeholder={"Password"}
          control={control}
          name="password"
          id="password"
          bordered
          rules={{
            required: t("common:form.validation.required"),
          }}
          errors={errors.password}
          type="password"
          style={{
            height: "40px",
          }}
        />
        <Button disabled={!isValid || isLoading}>{t("button:enter")}</Button>
      </form>
    </div>
  );
};

export default Login;
