import { numberFormat } from "utils/numberFormat";

export const fakeApi = {
  withdrawHistory: [
    {
      date: `2022\\11\\13 01:15:00`,
      method: "Bank Transfer",
      currency: "UAH",
      amount: "-200 000",
      fee: 348.2,
      balance: numberFormat(15342),
      card: "4441444*****144",
      support_code: "sadasdasdasd",
      status: "pending",
    },
    {
      date: `2022\\11\\13 01:15:00`,
      method: "Bank Transfer",
      currency: "UAH",
      amount: "-200 000",
      fee: 348.2,
      balance: numberFormat(15342),
      card: "4441444*****144",
      support_code: "sadasdasdasd",
      status: "done",
    },
    {
      date: `2022\\11\\13 01:15:00`,
      method: "Bank Transfer",
      currency: "UAH",
      amount: "-200 000",
      fee: 348.2,
      balance: numberFormat(15342),
      card: "4441444*****144",
      support_code: "sadasdasdasd",
      status: "cancel",
    },
  ],
  addMoneyHistory: [
    {
      date: `2022\\11\\13 01:15:00`,
      method: "Bank Transfer",
      currency: "UAH",
      amount: "+200 000",
      fee: 348.2,
      balance: numberFormat(15342),
      card: "4441444*****144",
      support_code: "sadasdasdasd",
      status: "pending",
    },
    {
      date: `2022\\11\\13 01:15:00`,
      method: "Bank Transfer",
      currency: "UAH",
      amount: "+200 000",
      fee: 348.2,
      balance: numberFormat(15342),
      card: "4441444*****144",
      support_code: "sadasdasdasd",
      status: "done",
    },
    {
      date: `2022\\11\\13 01:15:00`,
      method: "Bank Transfer",
      currency: "UAH",
      amount: "+200 000",
      fee: 348.2,
      balance: numberFormat(15342),
      card: "4441444*****144",
      support_code: "sadasdasdasd",
      status: "cancel",
    },
  ],
};
