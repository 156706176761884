import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import {
  getUsers,
  blockUserThunk,
  unblockUserThunk,
  deleteUserThunk,
  selectUsersData,
  selectUsersIsLoading,
  selectLoggedUserRole,
  selectUserBidsActivity,
  userBidsActivityThunk,
  selectUsersBidsPageSize,
  selectUsersBidsCurrentPage,
  clearUserBids,
} from "store/slices/users";
import PaginationComponent from "components/PaginationComponent";
import TableComponent, { TABLE_VIEW } from "components/TableComponent";
import Input from "components/Input";
import ModalUserHistory from "containers/ModalUserHistory";
import ModalWarning, { ErrorText, SuccessText } from "containers/ModalWarning";
import Loader from "components/Loader";
import { USER_TABLE_HEADERS } from "constants/table";
import { numberFormat } from "utils/numberFormat";
import { Trans, useTranslation } from "react-i18next";

import styles from "./index.module.scss";
import { useSearchParams } from "react-router-dom";
import useDebounce from "../../hooks/useDebounce/useDebounce";

const mutationUserDataToTableFormat = (items = []) => {
  const tableData = {
    header: USER_TABLE_HEADERS,
    data: items?.map((item) => ({
      ...item,
      balance: numberFormat(item.balance) || [],
    })),
  };

  return tableData;
};

const UsersPage = () => {
  const [isOpenModalHistory, setIsOpenModalHistory] = useState(false);
  const [modalHistoryType, setModalHistoryType] = useState("");
  const [isOpenWarningModal, setIsOpenWarningModal] = useState(false);
  const [warningModalType, setWarningModalType] = useState("");
  const [selectedItem, setSelectedItem] = useState(null);
  const [openedModal, setOpenedModal] = useState({
    key: "",
    id: "",
  });
  const [username, setUsername] = useState("");
  const isLoading = useSelector(selectUsersIsLoading);
  const listData = useSelector(selectUsersData);
  const dispatch = useDispatch();
  const { t } = useTranslation(["common", "modals"]);
  const loggedUserRole = useSelector(selectLoggedUserRole);
  const userBidsActivity = useSelector(selectUserBidsActivity);
  const userBidsPageSize = useSelector(selectUsersBidsPageSize);
  const userBidsCurrentPage = useSelector(selectUsersBidsCurrentPage);

  const [searchParams] = useSearchParams();
  const emailQuery = searchParams.get("email");
  const usernameQuery = searchParams.get("username");
  const langQuery = searchParams.get("lang");

  const [sortByObj, setSortByObj] = useState("");
  const [selectedPage, setSelectedPage] = useState(0);

  useEffect(() => {
    emailQuery || usernameQuery || langQuery
      ? setSortByObj({
          sortField: usernameQuery ? "username" : emailQuery ? "email" : "",
          sortDirection: usernameQuery
            ? usernameQuery
            : emailQuery
            ? emailQuery
            : "",
          page: selectedPage,
          lang: langQuery ? langQuery?.toUpperCase() : "",
        })
      : setSortByObj({ page: selectedPage });
  }, [emailQuery, usernameQuery, langQuery, selectedPage]);

  const { control, watch } = useForm({
    reValidateMode: "onChange",
    mode: "onTouched",
    defaultValues: {
      search: "",
    },
  });

  const keyword = useDebounce(() => watch("search"), 700);

  const openModal = (type, item) => {
    if (type === "delete" || type === "status") {
      setWarningModalType(type);
      setIsOpenWarningModal(true);
    } else {
      setModalHistoryType(type);
      setIsOpenModalHistory(true);
    }
    setSelectedItem(item);
  };

  useEffect(() => {
    keyword
      ? dispatch(getUsers({ ...sortByObj, keyword }))
      : dispatch(getUsers(sortByObj));
  }, [sortByObj, dispatch, keyword]);

  useEffect(() => {
    document.title = t("common:user.users");
  }, []);

  const blockUserHandler = async () => {
    await dispatch(blockUserThunk(selectedItem.id));

    dispatch(getUsers());
  };

  const unblockUserHandler = async () => {
    await dispatch(unblockUserThunk(selectedItem.id));

    dispatch(getUsers());
  };

  const deleteUserHandler = async () => {
    await dispatch(deleteUserThunk(selectedItem.id));

    dispatch(getUsers());
  };

  const userBidsActivityHandler = (username) => {
    dispatch(
      userBidsActivityThunk({
        username,
      })
    );
  };

  const handleUserBidsModalChangePage = async (selectedPage) => {
    const currentPage = selectedPage - 1;

    dispatch(userBidsActivityThunk({ username, currentPage }));
  };

  return (
    <div className={styles.users}>
      <div className={styles.header}>
        <span className={styles.title}>{t("common:user.users")}</span>
        <div className={styles.search}>
          <Input
            control={control}
            name="search"
            type="text"
            role="search"
            size="sm-2"
          />
        </div>
      </div>

      <div className={styles.body}>
        {!isLoading && (
          <>
            <div className={styles.table}>
              <TableComponent
                loggedUserRole={loggedUserRole}
                type={TABLE_VIEW.USERS}
                sort
                data={mutationUserDataToTableFormat(
                  listData?.content || listData
                )}
                openModal={(type, item) => {
                  setOpenedModal({
                    key: type,
                    id: item.id,
                  });
                  openModal(type, item);
                }}
                userHistoryOpenedModal={openedModal}
                deleteHandler={(item) => openModal("delete", item)}
                changeStatus={(item) => openModal("status", item)}
                userBidsActivityHandler={userBidsActivityHandler}
                setUsername={setUsername}
              />
            </div>
            <div className={styles.pagination}>
              <PaginationComponent
                itemsPerPage={1}
                totalPages={listData?.totalPages}
                forcePage={listData?.pageable?.pageNumber}
                onChanePage={(page) => {
                  setSelectedPage(page - 1);
                  dispatch(
                    getUsers({
                      ...sortByObj,
                      page: page - 1,
                    })
                  );
                }}
              />
            </div>
          </>
        )}
        {isLoading && <Loader />}
      </div>

      <ModalUserHistory
        isOpen={isOpenModalHistory}
        type={modalHistoryType}
        data={selectedItem}
        setIsOpenModal={() => {
          setModalHistoryType("");
          setIsOpenModalHistory(false);
          setSelectedItem(null);
          setOpenedModal({
            key: "",
            id: "",
          });
        }}
        cancelHandler={() => {
          setModalHistoryType("");
          setOpenedModal({
            key: "",
            id: "",
          });
          setIsOpenModalHistory(false);
          setSelectedItem(null);
          dispatch(clearUserBids());
        }}
        successHandler={() => {}}
        title={t(`common:user.${modalHistoryType}`)}
        userBidsActivity={userBidsActivity}
        handleUserBidsModalChangePage={handleUserBidsModalChangePage}
        isLoading={isLoading}
        loggedUserRole={loggedUserRole}
      />

      <ModalWarning
        isOpen={isOpenWarningModal}
        setIsOpenModal={() => setIsOpenWarningModal(!isOpenWarningModal)}
        title={
          warningModalType === "delete" ? (
            <Trans i18nKey="modals:deleteUser">
              Ти впевнений, що хочеш <ErrorText isUpperCase>видалити</ErrorText>{" "}
              цього користувача?
            </Trans>
          ) : selectedItem?.blocked ? (
            <Trans i18nKey="modals:unblockUser">
              <SuccessText isUpperCase>розблокувати</SuccessText> цього
              користувача?
            </Trans>
          ) : (
            <Trans i18nKey="modals:blockUser">
              <ErrorText isUpperCase>заблокувати</ErrorText> цього користувача?
            </Trans>
          )
        }
        successHandler={
          warningModalType === "delete"
            ? deleteUserHandler
            : selectedItem?.blocked
            ? unblockUserHandler
            : blockUserHandler
        }
      />
    </div>
  );
};

export default UsersPage;
