/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import {
  markItemAsDirty,
  markItemAsValid,
  markItemAsVisited,
  saveStepPageToStore,
  selectStepsPages,
  setStepPageData,
} from "../../../store/slices/steps";
import { selectSelectedEventCategoryId } from "store/slices/eventCategory";
import StepSave from "../../StepSave";
import CalendarInput from "../../../components/DatePicker";
import { parseDateToFormat } from "utils/date";
import moment from "moment";
import ValidationText from "../../../components/ValidationText";
import { useTranslation } from "react-i18next";

import "./index.scss";

const TimeRangeStepPage = ({ id }) => {
  const { t } = useTranslation(["common", "event"]);
  const selectedCategory = useSelector(selectSelectedEventCategoryId);
  const [isDatesCompareCorrect, setIsDatesCompareCorrect] = useState(false);
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [isStartValid, setIsStartValid] = useState(true);
  const [isBetEndValid, setIsBetEndValid] = useState(false);
  const [isFinishValid, setIsFinishValid] = useState(false);
  const dispatch = useDispatch();
  const storedData = useSelector(selectStepsPages);
  const dateNow = new Date();
  const initDate = (date) => {
    if (!date) {
      return dateNow;
    }
    return new Date(date);
  };
  const { control, handleSubmit, formState, setValue, watch } = useForm({
    reValidateMode: "onChange",
    mode: "onTouched",
    defaultValues: {
      startTime: initDate(storedData[id - 1].value["startTime"]),
      betEndTime: initDate(storedData[id - 1].value["betEndTime"]),
      finishTime: initDate(storedData[id - 1].value["finishTime"]),
    },
  });
  const { errors, isValid, isDirty } = formState;

  const startTime = watch("startTime");
  const betEndTime = watch("betEndTime");
  const finishTime = watch("finishTime");

  useEffect(() => {
    dispatch(markItemAsDirty(id, isDirty));
    dispatch(markItemAsVisited(id));
  }, [isDirty]);

  const checkIsValid = () => {
    if (isValid) {
      const start = moment.utc(startTime);
      const end = moment.utc(betEndTime);
      const finish = moment.utc(finishTime);

      if (start < end && end < finish) {
        dispatch(markItemAsValid(id, true));
        setIsDatesCompareCorrect(true);
        setIsStartValid(true);
        setIsBetEndValid(true);
        setIsFinishValid(true);
        return;
      }
      if (start > end) {
        setIsBetEndValid(false);
      }
      if (start < end) {
        setIsBetEndValid(true);
      }
      if (start > finish || end > finish) {
        setIsFinishValid(false);
      }
    }
    dispatch(markItemAsValid(id, false));
    setIsDatesCompareCorrect(false);
  };

  useEffect(() => {
    checkIsValid();
    dispatch(
      setStepPageData(id, {
        startTime: parseDateToFormat(startTime),
        betEndTime: parseDateToFormat(betEndTime),
        finishTime: parseDateToFormat(finishTime),
      })
    );
  }, [startTime, betEndTime, finishTime, isValid]);

  useEffect(() => {
    const showModal = (e) => {
      if (e.keyCode === 13 && e.ctrlKey) {
        e.preventDefault();
        setIsOpenModal(true);
      }
    };
    window.addEventListener("keydown", showModal);
    return () => window.removeEventListener("keydown", showModal);
  }, []);

  return (
    <div className="time-step">
      <div className="time-step__head">
        <StepSave
          isSuccess={isDatesCompareCorrect}
          isOpenModalOuter={isOpenModal}
          setIsOpenModalOuter={setIsOpenModal}
          successHandler={() => {
            if (isStartValid && isBetEndValid && isFinishValid) {
              dispatch(saveStepPageToStore(id, selectedCategory));
            }
          }}
          errorText={!isDatesCompareCorrect && t("event:incorrectDate")}
        />
      </div>
      <div className="time-step__body">
        <div className="time-step__body-form">
          <form onSubmit={handleSubmit(null)}>
            <div className="form-col">
              <div className="form-col__title">
                <span>{t("event:startTime")}</span>
              </div>
              <div className="form-col__calendar">
                <CalendarInput
                  control={control}
                  name={"startTime"}
                  placeholder={""}
                  isPlaceholderWhite
                  dateFormat={"dd.MM.yyyy, HH:mm 'GMT' XXX"}
                  customTimeInput
                  open
                  rules={{
                    required: t("common:form.validation.required"),
                  }}
                  errors={errors.startTime}
                />
              </div>
              <ValidationText isValid={!errors.startTime && isStartValid} />
            </div>
            <div className="form-col">
              <div className="form-col__title">
                <span>{t("event:betEndTime")}</span>
              </div>
              <div className="form-col__calendar">
                <CalendarInput
                  control={control}
                  selected={dateNow}
                  name={"betEndTime"}
                  placeholder={""}
                  open
                  isPlaceholderWhite
                  dateFormat={"dd.MM.yyyy, HH:mm 'GMT' XXX"}
                  customTimeInput
                  rules={{
                    required: t("common:form.validation.required"),
                  }}
                  errors={errors.betEndTime}
                />
              </div>
              <ValidationText isValid={!errors.betEndTime && isBetEndValid} />
            </div>
            <div className="form-col">
              <div className="form-col__title">
                <span>{t("event:finishTime")}</span>
              </div>
              <div className="form-col__calendar">
                <CalendarInput
                  control={control}
                  selected={dateNow}
                  name={"finishTime"}
                  placeholder={""}
                  open
                  isPlaceholderWhite
                  dateFormat={"dd.MM.yyyy, HH:mm 'GMT' XXX"}
                  customTimeInput
                  rules={{
                    required: t("common:form.validation.required"),
                  }}
                  errors={errors.finishTime}
                />
              </div>
              <ValidationText isValid={!errors.finishTime && isFinishValid} />
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default React.memo(TimeRangeStepPage);
