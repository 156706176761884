import { toast } from "react-hot-toast";
import NotepadImg from "../assets/toasters/NotepadImg";
import LikeImg from "../assets/toasters/LikeImg";
import CheckedImg from "../assets/toasters/CheckedImg";
import FolderImg from "../assets/toasters/FolderImg";
import CoinsImg from "../assets/toasters/CoinsImg";
import { TOASTERS_PALLET } from "../constants/colors";
import BinImg from "../assets/toasters/BinImg";
import BlockImg from "../assets/toasters/BlockImg";
import UnblockImg from "../assets/toasters/UnblockImg";
import BalanceDec from "../assets/toasters/BalanceDec";
import NeedExpirationImg from "../assets/toasters/NeedExpirationImg";
import EventUsersImg from "../assets/toasters/EventUsersImg";

const toasterConfig = (text, img, color) => {
  return toast(text, {
    icon: img,
    style: {
      borderRadius: "10px",
      borderRight: `10px solid ${color}`,
      padding: "15px",
      background: "linear-gradient(135deg, #232948 0%, #1F2542 100%)",
      color,
      boxShadow:
        "3px 3px 8px 0 #0D101CE5, -3px -3px 6px 0px #353E6EE5, 3px -3px 6px 0px #0D101C33, -3px 3px 6px 0px #0D101C33, -1px -1px 2px 0px #0D101C80 inset, 1px 1px 2px 0px #353E6E4D inset",
      minWidth: "460px",
      maxWidth: "100%",
      fontSize: "20px",
      fontWeight: 700,
    },
  });
};

export const toasterCreator = {
  events: {
    categoryAdded: () =>
      toasterConfig("Категорію додано", <NotepadImg />, TOASTERS_PALLET.green),
    savedStep: () =>
      toasterConfig("Збережено", <LikeImg />, TOASTERS_PALLET.green),
    eventCreated: () =>
      toasterConfig("Подія створена", <NotepadImg />, TOASTERS_PALLET.green),
    eventUpdated: () =>
      toasterConfig("Подія оновлена", <CheckedImg />, TOASTERS_PALLET.green),
    eventPublished: () =>
      toasterConfig(
        "Подія опублікована",
        <NotepadImg />,
        TOASTERS_PALLET.green
      ),
    expirationSuccess: () =>
      toasterConfig(
        "Експірація пройшла успішно",
        <LikeImg />,
        TOASTERS_PALLET.green
      ),
    expirationFixed: () =>
      toasterConfig(
        "Експірацію виправлено",
        <CheckedImg />,
        TOASTERS_PALLET.green
      ),
    eventCanceled: () =>
      toasterConfig("Подія скасована", <FolderImg />, TOASTERS_PALLET.red),
    eventCalculated: () =>
      toasterConfig(
        "Подія успішно розрахована",
        <CoinsImg />,
        TOASTERS_PALLET.yellow
      ),
    eventNeedExpiration: () => toasterConfig("Треба зробити експірацію", <NeedExpirationImg />, TOASTERS_PALLET.yellow),
    eventNeedCalculateUsers: () => toasterConfig(
    `По події треба розрахувати
    користувачів`,
      <EventUsersImg />, TOASTERS_PALLET.yellow)
  },

  users: {
    userBlocked: () =>
      toasterConfig(
        "Користувача заблоковано",
        <BlockImg />,
        TOASTERS_PALLET.yellow
      ),
    userUnblocked: () =>
      toasterConfig(
        "Користувача розблоковано",
        <UnblockImg />,
        TOASTERS_PALLET.green
      ),
    userDeleted: () =>
      toasterConfig("Користувача видалено", <BinImg />, TOASTERS_PALLET.red),
    userCreated: () =>
      toasterConfig("Користувача додано", <LikeImg />, TOASTERS_PALLET.green),
    userUpdated: () =>
      toasterConfig(
        "Користувача відредаговано",
        <LikeImg />,
        TOASTERS_PALLET.green
      ),
    passwordReset: () =>
      toasterConfig(
        "Відновлення паролю успішне",
        <LikeImg />,
        TOASTERS_PALLET.green
      ),
  },

  images: {
    imgAdded: () =>
      toasterConfig("Зображення додано", <NotepadImg />, TOASTERS_PALLET.green),
    imgDeleted: () =>
      toasterConfig("Зображення видалено", <BinImg />, TOASTERS_PALLET.red),
    imgUpdated: () =>
      toasterConfig(
        "Зображення оновлено",
        <CheckedImg />,
        TOASTERS_PALLET.green
      ),
  },

  notifications: {
    notificationAdded: () =>
      toasterConfig(
        "Повідомлення додано",
        <NotepadImg />,
        TOASTERS_PALLET.green
      ),
    notificationDeleted: () =>
      toasterConfig("Повідомлення видалено", <BinImg />, TOASTERS_PALLET.red),
    notificationUpdated: () =>
      toasterConfig(
        "Повідомлення оновлено",
        <CheckedImg />,
        TOASTERS_PALLET.green
      ),
  },

  updateBalance: {
    balanceAdded: () =>
      toasterConfig("Баланс додано", <CoinsImg />, TOASTERS_PALLET.yellow),
    balanceDec: () =>
      toasterConfig("Знято з балансу", <BalanceDec />, TOASTERS_PALLET.red),
  },
};
