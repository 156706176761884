import React, { useState } from "react";
import classNames from "classnames/bind";
import styles from "./EventCard.module.scss";
import Button from "components/Button";
import DeleteIcon from "assets/svg/DeleteIcon";
import EditIcon from "assets/svg/EditIcon";
import TimeContainer from "components/TimeContainer";
import { useNavigate } from "react-router";
import LangComponent from "components/LangComponent";
import ImageContainer from "containers/ImageContainer";
import { useDispatch, useSelector } from "react-redux";
import {
  selectIsEventLoading,
  publishEvent,
  deleteEvent,
  cancelEvent,
  closeEvent,
} from "store/slices/event";
import { NotificationManager } from "react-notifications";
import ModalWarning, { ErrorText, SuccessText } from "containers/ModalWarning";
import ModalFormEvent from "containers/ModalFormEvent";
import ModalCancelDetail from "containers/ModalCancelDetail";
import { expireEvent } from "store/slices/event";
import ModalEditWinDirection from "containers/ModalEditWinDirection";
import { parseDateToFormat } from "utils/date";
import { numberFormat } from "utils/numberFormat";
import TooltipComponent from "components/Tooltip";
import moment from "moment";
import { Trans, useTranslation } from "react-i18next";
import { toasterCreator } from "../../../utils/toasterCreator";

const cx = classNames.bind(styles);

export const EVENT_STATUS = {
  CREATED: "CREATED",
  PUBLISHED: "PUBLISHED",
  ACTIVE: "ACTIVE",
  PASSIVE: "PASSIVE",
  EXPIRED: "EXPIRED",
  FINISHED: "FINISHED",
  CLOSED: "CLOSED",
  CANCELED: "CANCELED",
};

const WIN_DIRECTION = {
  "expiration-yes": "BID_UP",
  "expiration-no": "BID_DOWN",
};

const EventCard = ({
  item,
  isModalView,
  onSuccess = () => {},
  getEvents,
  lang,
  content,
  type = "",
}) => {
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [isOpenFormModal, setIsOpenFormModal] = useState(false);
  const [isOpenDetailModal, setOpenDetailModal] = useState(false);
  const [isOpenEditDirectionModal, setIsOpenEditDirectionModal] =
    useState(false);
  const [modalType, setModalType] = useState(null);
  const [winDirection, setWinDirection] = useState(null);
  const [expirationModalData, setExpirationModalData] = useState(null);
  const [individualLang, setIndividualLang] = useState(
    localStorage.getItem("lang")
  );
  const navigate = useNavigate();
  const isLoading = useSelector(selectIsEventLoading);
  const dispatch = useDispatch();
  const { t } = useTranslation(["button", "event", "modals", "notification"]);
  let displayedContent;

  if (content) {
    displayedContent = content[individualLang].find(
      (obj) => obj.uuid === item.uuid
    );
  } else if (isModalView) {
    displayedContent = item;
  }

  const openModal = (type) => {
    setModalType(type);
    if (type === "detail") {
      return setOpenDetailModal(true);
    }
    if (type === "edit-exp") {
      return setIsOpenEditDirectionModal(true);
    }
    return setIsOpenModal(true);
  };

  const editEvent = (uuid) => {
    if (displayedContent?.status === EVENT_STATUS.CREATED) {
      navigate(`/events/unpublished-event/edit/${uuid}`);
    }
    if (displayedContent?.status === EVENT_STATUS.PUBLISHED) {
      navigate(`/events/published/edit/${uuid}`);
    }
  };

  const publish = () => {
    setModalType(null);
    dispatch(
      publishEvent({
        eventUuid: displayedContent?.uuid,
      })
    )
      .unwrap()
      .then(() => {
        onSuccess();
      })
      .catch((err) => {
        NotificationManager.error(
          "",
          t("notification:errorPublishTitle"),
          6000
        );
      });
  };

  const deleteHandler = () => {
    setModalType(null);
    dispatch(deleteEvent({ id: displayedContent?.uuid }))
      .unwrap()
      .then(() => {
        NotificationManager.success(
          "",
          t("notification:succesDeleteTitle"),
          6000
        );
        onSuccess();
      })
      .catch((err) => {
        NotificationManager.error("", t("notification:errorDeleteTitle"), 6000);
      });
  };

  const cancelHandler = (data) => {
    setModalType(null);
    setIsOpenFormModal(false);
    dispatch(
      cancelEvent({
        eventUuid: displayedContent?.uuid,
        cancelingInfo: data,
      })
    )
      .unwrap()
      .then(() => {
        onSuccess();
      })
      .catch((err) => {
        NotificationManager.error("", t("notification:errorCancelTitle"), 6000);
      });
  };

  const setExpirate = () => {
    setModalType(null);
    setIsOpenFormModal(false);
    dispatch(
      expireEvent({
        eventUuid: displayedContent?.uuid,
        expirationReference: expirationModalData,
        winDirection,
        type,
      })
    )
      .unwrap()
      .then(() => {
        onSuccess();
      })
      .catch((err) => {
        NotificationManager.error(
          "",
          t("notification:errorExpirationTitle"),
          6000
        );
      });
  };

  const calculateEvent = () => {
    setModalType(null);
    setIsOpenFormModal(false);
    dispatch(
      closeEvent({
        eventUuid: displayedContent?.uuid,
      })
    )
      .unwrap()
      .then(() => {
        onSuccess();
      })
      .catch((err) => {
        NotificationManager.error(
          "",
          t("notification:errorCalculateTitle"),
          6000
        );
      });
  };

  const successWarningHandler = () => {
    if (modalType === "publish") {
      return publish();
    }
    if (modalType === "cancel") {
      setIsOpenModal(false);
      return setIsOpenFormModal(true);
    }
    if (modalType === "expiration-yes" || modalType === "expiration-no") {
      setIsOpenModal(false);
      setWinDirection(WIN_DIRECTION[modalType]);
      setModalType("edit-exp");
      return setIsOpenFormModal(true);
    }
    if (modalType === "edit-exp") {
      setIsOpenModal(false);
      return setExpirate();
    }
    if (modalType === "calculate") {
      return calculateEvent();
    }
    deleteHandler();
  };

  const successFormModalHandler = (data) => {
    if (modalType === "cancel") {
      return cancelHandler(data);
    }
    if (
      modalType === "edit-exp" ||
      modalType === "expiration-yes" ||
      modalType === "expiration-no"
    ) {
      setExpirationModalData(data);
      setIsOpenModal(true);
    }
  };

  return (
    <div
      className={cx("card", {
        "modal-view": isModalView,
      })}
    >
      <div className={cx("card__content")}>
        {!isModalView && (
          <div className={cx("card__img")}>
            <ImageContainer imageUuid={displayedContent?.imageUuid} />
          </div>
        )}

        <div className={cx("card__action")}>
          <TimeContainer
            // createTime={"01 Jan 1970 00:00:00 GMT"}
            createTime={moment(displayedContent?.startTime).subtract(10, "day")}
            startTime={displayedContent?.startTime}
            betEndTime={displayedContent?.betEndTime}
            finishTime={displayedContent?.finishTime}
            isTimerDisabled={displayedContent?.status === EVENT_STATUS.CANCELED}
            eventStatus={displayedContent?.status}
          />

          {displayedContent?.status !== EVENT_STATUS.PUBLISHED &&
          displayedContent?.status !== EVENT_STATUS.CREATED ? (
            <div className={cx("card__amount")}>
              <span>{t("event:commonPool")}</span>
              <span>{displayedContent?.totalAmount} &euro;</span>
            </div>
          ) : (
            <div className={cx("card__btns")}>
              <div className={"card__btn"}>
                {displayedContent?.status === EVENT_STATUS.CREATED && (
                  <DeleteIcon
                    className={cx("card__btn-icon")}
                    onClick={() => {
                      openModal("delete");
                    }}
                  />
                )}
                {(displayedContent?.status === EVENT_STATUS.PUBLISHED ||
                  displayedContent?.status === EVENT_STATUS.CREATED) && (
                  <EditIcon
                    className={cx("card__btn-icon")}
                    onClick={() => editEvent(displayedContent?.uuid)}
                  />
                )}
              </div>
            </div>
          )}
        </div>

        {displayedContent?.status !== EVENT_STATUS.PUBLISHED &&
          displayedContent?.status !== EVENT_STATUS.CREATED && (
            <div
              className={cx("card__vote", {
                active: displayedContent?.status === EVENT_STATUS.FINISHED,
                "no-interactive":
                  displayedContent?.status === EVENT_STATUS.ACTIVE ||
                  displayedContent?.status === EVENT_STATUS.PASSIVE,
              })}
            >
              <div
                className={cx("card__vote-yes", {
                  selected: displayedContent?.expirationValue === "BID_UP",
                })}
                onClick={() => {
                  if (displayedContent?.status === EVENT_STATUS.FINISHED) {
                    openModal("expiration-yes");
                  }
                }}
              >
                <span>{t("event:bidYes")}</span>
                <span>{displayedContent?.bidUpCoefficient}x</span>
              </div>
              <div
                className={cx("card__vote-no", {
                  selected: displayedContent?.expirationValue === "BID_DOWN",
                })}
                onClick={() => {
                  if (displayedContent?.status === EVENT_STATUS.FINISHED) {
                    openModal("expiration-no");
                  }
                }}
              >
                <span>{t("event:bidNo")}</span>
                <span>{displayedContent?.bidDownCoefficient}x</span>
              </div>
            </div>
          )}

        <div className={cx("card__info")}>
          <div className={cx("card__info-row")}>
            <span>{t("event:subCategory")}</span>
            <span>{displayedContent?.subCategory}</span>
          </div>
          <div className={cx("card__info-row")}>
            <span>{t("event:eventDescription")}</span>
            <span>
              <TooltipComponent
                line={2}
                count={50}
                trigger={
                  <span className={cx("text")}>{displayedContent?.title}</span>
                }
                textTooltip={displayedContent?.title}
              />
            </span>
          </div>
          <div className={cx("card__info-row")}>
            <span>{t("event:eventAdditionalInfo")}</span>
            <span>
              <TooltipComponent
                line={2}
                count={50}
                trigger={
                  <span className={cx("text")}>
                    {displayedContent?.additionalInfo}
                  </span>
                }
                textTooltip={displayedContent?.additionalInfo}
              />
            </span>
          </div>
          <div className={cx("card__info-row")}>
            <span>{t("event:startTime")}</span>
            <span>
              {parseDateToFormat(
                displayedContent?.startTime,
                "DD.MM.YYYY HH:mm UTCZ"
              )}
            </span>
          </div>
          <div className={cx("card__info-row")}>
            <span>{t("event:betEndTime")}</span>
            <span>
              {parseDateToFormat(
                displayedContent?.betEndTime,
                "DD.MM.YYYY HH:mm UTCZ"
              )}
            </span>
          </div>
          <div className={cx("card__info-row")}>
            <span>{t("event:finishTime")}</span>
            <span>
              {parseDateToFormat(
                displayedContent?.finishTime,
                "DD.MM.YYYY HH:mm UTCZ"
              )}
            </span>
          </div>
          <div className={cx("card__info-row")}>
            <span>{t("event:startReference")}</span>
            <span>{numberFormat(displayedContent?.startReference)}</span>
          </div>
          <div className={cx("card__info-row")}>
            <span>{t("event:controlReference")}</span>
            <span>{numberFormat(displayedContent?.controlReference)}</span>
          </div>
          {(displayedContent?.status === EVENT_STATUS.EXPIRED ||
            displayedContent?.status === EVENT_STATUS.CLOSED ||
            displayedContent?.status === EVENT_STATUS.CANCELED) && (
            <div className={cx("card__info-row")}>
              <span>{t("event:expirationValue")}</span>
              <span>
                {displayedContent?.expirationReference === null
                  ? "-"
                  : displayedContent?.expirationReference}
              </span>
            </div>
          )}
        </div>

        {!isModalView && (
          <>
            <LangComponent
              getEvents={getEvents}
              isEventCard
              individualLang={individualLang}
              setIndividualLang={setIndividualLang}
            />

            <div className={cx("card__button")}>
              {displayedContent?.status === EVENT_STATUS.CREATED && (
                <Button
                  disabled={isLoading}
                  onClick={() => {
                    openModal("publish");
                  }}
                >
                  {t("button:publish")}
                </Button>
              )}

              {(displayedContent?.status === EVENT_STATUS.PUBLISHED ||
                displayedContent?.status === EVENT_STATUS.FINISHED ||
                displayedContent?.status === EVENT_STATUS.ACTIVE ||
                displayedContent?.status === EVENT_STATUS.PASSIVE) && (
                <Button
                  disabled={isLoading}
                  onClick={() => {
                    openModal("cancel");
                  }}
                >
                  {t("button:cancel")}
                </Button>
              )}

              {displayedContent?.status === EVENT_STATUS.CLOSED && (
                <Button disabled>{t("button:finishedEvent")}</Button>
              )}

              {displayedContent?.status === EVENT_STATUS.CANCELED && (
                <Button
                  onClick={() => {
                    openModal("detail");
                  }}
                >
                  {t("button:detail")}
                </Button>
              )}

              {displayedContent?.status === EVENT_STATUS.EXPIRED && (
                <>
                  <Button
                    onClick={() => {
                      openModal("calculate");
                    }}
                  >
                    {t("button:calculate")}
                  </Button>
                  <Button
                    onClick={() => {
                      openModal("edit-exp");
                    }}
                  >
                    {t("button:editExp")}
                  </Button>
                </>
              )}
            </div>
          </>
        )}

        <ModalWarning
          isOpen={isOpenModal}
          setIsOpenModal={() => setIsOpenModal(!isOpenModal)}
          cancelHandler={() => setModalType(null)}
          successHandler={successWarningHandler}
          title={
            modalType === "publish" || modalType === "edit-exp" ? (
              <Trans i18nKey="modals:allEnteredDataCorrect">
                Ти точно впевнений, що всі{" "}
                <ErrorText>ДАНІ ПРАВИЛЬНО ВВЕДЕНІ</ErrorText>?
              </Trans>
            ) : modalType === "expiration-yes" ? (
              <Trans i18nKey="modals:bidYesEvent">
                Ти точно впевнений, що перемогла позиція{" "}
                <SuccessText isUpperCase>Так</SuccessText>?
              </Trans>
            ) : modalType === "expiration-no" ? (
              <Trans i18nKey="modals:bidNoEvent">
                Ти точно впевнений, що перемогла позиція{" "}
                <ErrorText isUpperCase>Ні</ErrorText>?
              </Trans>
            ) : modalType === "calculate" ? (
              <Trans i18nKey="modals:calculateEvent">
                Ти точно впевнений, що можна{" "}
                <SuccessText>розраховувати</SuccessText>?
              </Trans>
            ) : (
              <Trans i18nKey="modals:cancelEvent">
                Ти точно впевнений, що хочеш{" "}
                <ErrorText>СКАСУВАТИ ПОДІЮ</ErrorText>?
              </Trans>
            )
          }
        />

        <ModalEditWinDirection
          isOpen={isOpenEditDirectionModal}
          description={displayedContent?.cancelingInfo}
          bidUpCoefficient={displayedContent?.bidUpCoefficient}
          bidDownCoefficient={displayedContent?.bidDownCoefficient}
          setIsOpenModal={() =>
            setIsOpenEditDirectionModal(!isOpenEditDirectionModal)
          }
          successHandler={(res) => {
            setWinDirection(res);
            setIsOpenFormModal(true);
          }}
          closeHandler={() => {
            setModalType(null);
          }}
        />

        <ModalFormEvent
          title={
            modalType === "cancel"
              ? t("modals:cancelReason")
              : t("modals:enterExpValue")
          }
          isOpen={isOpenFormModal}
          setIsOpenModal={() => setIsOpenFormModal(!isOpenFormModal)}
          successHandler={successFormModalHandler}
          cancelHandler={() => {
            setModalType(null);
          }}
        />

        <ModalCancelDetail
          isOpen={isOpenDetailModal}
          description={displayedContent?.cancelingInfo}
          data={{
            subCategory: displayedContent?.subCategory,
            title: displayedContent?.title,
          }}
          setIsOpenModal={() => setOpenDetailModal(!isOpenDetailModal)}
          closeHandler={() => {
            setModalType(null);
          }}
        />
      </div>
    </div>
  );
};

export default EventCard;
