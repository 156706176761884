import React, { useState } from "react";
import classNames from "classnames/bind";

import styles from "./textareaInput.module.scss";
import { useController } from "react-hook-form";

const cx = classNames.bind(styles);

const TexareaInput = (props) => {
  const { field } = useController(props);
  const [focus, setFocus] = useState(false);
  const onSelectChange = (value) => {
    field.onChange(value);
    props.onSelectChange?.();
    props.onChange?.(value.target.value);
  };

  return (
    <>
      <textarea
        {...field}
        {...props}
        className={props.errors ? cx("textarea", "error") : cx("textarea")}
        placeholder={props.placeholder}
        disabled={props.disabled}
        value={props.value || (field.value === null ? "" : field.value)}
        onFocus={
          props.additional
            ? () => {
                setFocus(true);
              }
            : props.onFocus
        }
        onBlur={() => setFocus(false)}
        onChange={onSelectChange}
      />
    </>
  );
};

export default TexareaInput;
